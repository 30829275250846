import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Page from "../templates/page";

// markup
export const query = graphql`
	query FrontpageQuery {
		sanityPage(slug: { current: { eq: "home" } }, i18n_lang: { eq: "en" }) {
			i18n_lang
			title
			seoTitle
			_rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;
const IndexPage = ({ location, data }) => {
	console.log(data);
	return (
		<>
			<script
				dangerouslySetInnerHTML={{
					__html: `
		  (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "k7z69g9t5b");
        `,
				}}
			/>

			<SEO
				title={data.sanityPage.title}
				description="BuduCloud’s CRM & ERP are tailored specifically for your business needs providing you with a flexible & highly adaptable user-friendly experience."
			/>
			<Page location={location} data={data} />
		</>
	);
};

export default IndexPage;
